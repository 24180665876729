
import { Component, Vue } from 'vue-property-decorator';
import injectMarkdownToHmtElement from '@/services/markdownService';
import { getNews, NewsModel } from '@/providers/newsroom-api-provider';
import * as appStore from '@/store/modules/app';
import NewsSnippet from '@/components/NewsSnippet.vue';

@Component({
  components: {
    NewsSnippet,
  },
})
export default class NewsPost extends Vue {
  private markdownElementId = 'news-post-container';

  private appState = appStore;

  public id = 0;

  public post: NewsModel | null = null;

  beforeMount() {
    this.id = parseInt(this.$route.params.id, 0);
  }

  async mounted() {
    this.getData();
  }

  get title() {
    return this.post?.Title;
  }

  get imageHeaderUrlStyle() {
    return this.post?.ImageHeaderUrl
      ? { 'background-image': `url('${this.post.ImageHeaderUrl}')` }
      : {};
  }

  get authors() {
    return this.post?.Authors.join(', ');
  }

  async getData() {
    const { currentLanguage } = this.appState.getters.getAppState;
    this.post = await getNews(this.id, currentLanguage);
    if (this.post?.Content === undefined || this.post.Content == null) {
      await this.$router.push({ name: 'notfound' });
      return;
    }

    injectMarkdownToHmtElement(this.markdownElementId, this.post.Content);
  }

  onIdChanged(newNewsId: number) {
    this.id = newNewsId;
    this.getData();
  }
}
