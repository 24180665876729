import marked from 'marked';

export default function injectMarkdownToHmtElement(elementId: string, content: string) {
  const htmlElement = document.getElementById(elementId);
  if (!htmlElement) {
    return;
  }

  htmlElement.innerHTML = marked(content);
}
